




















import Vue from 'vue';
import * as _ from 'lodash';
import conf from '../conf';
import * as Helpers from '../services/helpers';
import { formatValue as format } from '../../../shared/v_utils'
import * as JsDiff from 'diff';

function computeComparisons(v, homonyme) {   
        let sameAttrs = {};
        const ignored_attrs = [ 'uid', 'supannAliasLogin', 'score' ];
        return Object.keys(conf.default_attrs_opts).filter(attr => (
            (attr in homonyme) && !_.includes(ignored_attrs, attr) && !attr.match(/^global_/)
        )).map(attr => {
            var val2 = format(homonyme[attr]);
            var val1 = format(v[attr]);
            if (!val1 && attr === 'birthName' && val2) {
                val1 = format(v['sn']);
            }
            [ val1, val2 ] = [ val1, val2 ].map(Helpers.maybeFormatPhone("0"));
            const same = Helpers.equalsIgnoreCase(val1, val2);
            const skip = !val1 && attr === 'altGivenName' && sameAttrs['givenName'];

            let cmp;
            if (attr === 'jpegPhoto') {
                [ val1, val2 ] = [ val1, val2 ].map(val => `<img src="${val}">`);
                cmp = [ val1, same ? "<i>identique</i>" : val2 ];
            } else if (same) { 
                sameAttrs[attr] = true;
                cmp = [ val1, same ? "<i>identique</i>" : val2 ];
            } else {
                cmp = Helpers.formatDifferences(val1, val2, JsDiff);
            }
            return { attr, cmp, skip };
        });
}

export default Vue.extend({
    props: ['v', 'homonyme'],
    computed: {
        comparisons() {
            return computeComparisons(this.v, this.homonyme);
        },
    },
})
